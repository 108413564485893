<template>
  <b-overlay :show="loading">
    <b-card header="Carica documento livello 2">
      <b-card-body>

        <validation-observer ref="rules">
          <b-form @submit.prevent="">

            <b-row>
              <b-col cols="12">
                <cs-validation
                  label="Csv"
                  rules="required"
                >
                  <template slot-scope="props">

                    <b-form-file
                      v-model="model.file"
                      accept=".csv"
                      placeholder="Seleziona o trascina un csv qui..."
                      drop-placeholder="Trascina un csv qui..."
                      :state="props.errors.length > 0 ? false : null"
                    />
                  </template>
                </cs-validation>

              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </b-card-body>
      <b-card-footer>
        <cs-submit-button :click="add">
          Aggiorna
        </cs-submit-button>
      </b-card-footer>
    </b-card>
  </b-overlay>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      model: {
        file: null,
      },
    }
  },
  methods: {
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.livello.create(this.model)
            .then(() => {
              // const canView = this.$grants.OPERE_DETTAGLIO.can
              // if (canView) this.$routing.OPERE_DETTAGLIO.push(res.data)
              // else
              this.showAddSuccessMessage()
            })
            .catch(res => {
              this.showReposonseMessage(res)
            })
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
